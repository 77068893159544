<!-- eslint-disable vue/max-len -->
<!-- eslint-disable max-len -->
<template>
  <div class="privacy-policy">
    <h1>POLÍTICA ANTICORRUPÇÃO</h1>

    <h2>1. INTRODUÇÃO E OBJETIVO</h2>
    <p>Esta Política Anticorrupção fornece instruções e diretrizes aos colaboradores da PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA e parceiros de negócios que atuam em seu nome ou em seu favor, orientando-os na prevenção, detecção e tratamento de práticas e/ou condutas que possam configurar ou aparentar atos de corrupção, suborno ou propina. Esta Política reforça o compromisso da PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA em conduzir seus negócios pautados pelos mais elevados padrões de integridade, transparência e legalidade. Isso inclui atuar de forma ética, com respeito e observância às leis e regulamentação aplicáveis de anticorrupção, nos países onde atuamos e aqueles que podem ser aplicáveis aos nossos negócios. A PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA proíbe e repudia qualquer prática antiética e busca agir sempre na mais absoluta conformidade à legislação vigente, em especial, a Lei nº 12.846/2013 (“Lei Anticorrupção”) e do Decreto 11.129/2022 e regras de concorrências, processos de compras, licitações e contratos, no setor público e no setor privado.</p>

    <p>Esta Política tem como principais objetivos:</p>

    <ul>
      <li>Apresentar as regras de conduta perante as relações com as autoridades, agentes de Órgãos Públicos e clientes públicos e privados;</li>
      <li>Orientar os colaboradores, evitando possíveis conflitos e violações das leis e normas anticorrupção;</li>
      <li>Assegurar que todas as decisões observem as leis e normas aplicáveis ao negócio, bem como o Código de Conduta vigente, comprometendo-se a satisfazer os requisitos e objetivos, realizar análises críticas e buscar pela melhoria contínua do sistema de gestão antissuborno e compliance.</li>
    </ul>

    <h2>2. ABRANGÊNCIA</h2>
    <p>A Política Anticorrupção dispõe das diretrizes a serem seguidas por todos os colaboradores, sem distinção de cargos e posições, incluindo os Terceiros que atuam em nome da PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA.</p>

    <h2>3. CORRUPÇÃO (DEFINIÇÃO)</h2>
    <p>Conforme a Lei n° 12.846/13, que prevê atos lesivos à administração pública, a corrupção pode ser entendida como a ação de prometer, oferecer ou dar, direta ou indiretamente, vantagem indevida a agente público, ou a terceira pessoa a ele relacionada. Não obstante, a corrupção também está prevista no ordenamento jurídico brasileiro como crime, em duas modalidades distintas:</p>

    <ul>
      <li>Corrupção Ativa: Consiste na ação do Agente Privado em oferecer, prometer ou dar, direta ou indiretamente, compensações ilícitas em troca de vantagens pessoais a um agente público nacional ou estrangeiro, ou à terceira pessoa, em troca de benefícios pessoais ou a outros.</li>
      <li>Corrupção Passiva: Consiste na ação do Agente Público de solicitar ou receber, para si ou para outrem, direta ou indiretamente, ainda que fora da função ou antes de assumi-la, mas em razão dela, vantagem indevida, ou aceitar promessa de tal vantagem.</li>
    </ul>

    <p>A Lei Anticorrupção prevê a responsabilização objetiva, administrativa e civil das pessoas jurídicas pela prática de atos de corrupção contra a administração pública, nacional ou estrangeira.</p>

    <h2>4. DIRETRIZES PARA PARTICIPAÇÃO EM LICITAÇÕES PÚBLICAS</h2>
    <p>Todos os funcionários, estagiários, colaboradores e diretores que estejam envolvidos diretamente com o processo de vendas para o Governo, precisam estar alinhados e comprometidos com esta Política, com Código de Conduta e com a legislação vigente, evitando assim, qualquer risco de corrupção contra a administração pública, ficando expressamente vedado:</p>

    <ul>
      <li>Fraudar, frustrar ou interferir, mediante ajuste, combinação ou qualquer outro expediente, a natureza competitiva de procedimentos licitatórios;</li>
      <li>Prometer, oferecer, autorizar ou dar vantagem indevida a agente público ou pessoas a ele relacionadas, por meio de colaboradores ou terceiros, visando obter vantagem ou benefício em concorrências, licitações e contratos com a Administração Pública;</li>
      <li>Criar, de modo fraudulento ou irregular, pessoa jurídica para participar de licitação pública ou celebrar contratos com a administração pública;</li>
      <li>Prometer, oferecer ou pagar taxas de facilitação ou de urgência (suborno);</li>
      <li>Prometer ou oferecer pagamento em dinheiro ou qualquer item de valor a qualquer pessoa, visando assegurar vantagem indevida, obter, reter ou direcionar negócios a outra pessoa ou entidade;</li>
      <li>Solicitar, aceitar promessa ou receber qualquer tipo de tratamento privilegiado;</li>
      <li>Obter para si, para a empresa ou para terceiros, qualquer vantagem indevida ou benefícios injustificados, alterações ou prorrogações de contratos públicos;</li>
      <li>Manipular indevidamente o equilíbrio econômico-financeiro dos contratos com Governo, por meio de fraude, acordos, parcerias ou outros atos lesivos;</li>
      <li>Impedir indevidamente, manipular ou fraudar qualquer ato ou fase de licitação, contrato público ou qualquer outro ato relacionado;</li>
    </ul>

    <p>Os contratos firmados com Órgãos Públicos devem ser controlados pela Diretoria responsável e estarão sujeitos a análise e monitoramento pelo Time Jurídico.</p>

    <h2>5. RELACIONAMENTO COM CLIENTES PÚBLICOS E PRIVADOS</h2>
    <p>Objetivando a satisfação dos clientes da PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA e a manutenção de relacionamentos íntegros e transparentes, todo e qualquer relacionamento com clientes públicos e privados deverá observar:</p>

    <ul>
      <li>Transparência na relação, fornecendo com prioridade e clareza toda e qualquer informação de interesse do cliente, desde que não esteja protegida por sigilo;</li>
      <li>Priorizar a satisfação do cliente sem infringir qualquer norma ética ou cultural da PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA;</li>
      <li>Os contratos celebrados deverão conter cláusula de compliance e anticorrupção, devendo conter o expresso comprometimento com o Código de Conduta, Política Anticorrupção e Política de Compliance da PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA.</li>
      <li>Respeitar as normas de proteção ao consumidor, agindo com honestidade na publicidade e processo de vendas.</li>
      <li>Conferir atenção especial ao pós-vendas, permitindo o amplo acesso e esclarecimento sobre os serviços prestados;</li>
      <li>Observância às disposições da Lei 13.709/18, que regulamenta a proteção de dados pessoais e da Lei 12.965/14 que regulamenta o Marco Civil da Internet;</li>
    </ul>

    <h3>5.1 Diretrizes para reuniões com clientes públicos</h3>
    <p>O relacionamento com clientes públicos deverá, ainda, seguir rigorosamente os preceitos da Lei Anticorrupção, Lei de Licitações, Lei da Improbidade Administrativa e normas correlatas, visando a construção e manutenção de uma relação ética, legítima e transparente. Sempre que for necessária a realização de reuniões com agentes do Poder Público, fica determinada a adoção dos seguintes procedimentos:</p>

    <ul>
      <li>Ser realizada comunicação prévia com ajustamento da data, horário, modalidade (presencial ou virtual), finalidade e participantes de ambos os lados.</li>
      <li>A comunicação de que trata o item anterior deverá ser realizada apenas dos e-mails funcionais tanto dos colaboradores da PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA, quanto dos agentes públicos. Ficando, desde logo, estabelecido que o Time Jurídico e os Membros da Alta Direção estejam em cópia.</li>
      <li>Sempre que possível devem ser evitadas as tratativas individuais entre os colaboradores da PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA e agentes públicos e sem agendamento prévio.</li>
      <li>As reuniões poderão ocorrer nas modalidades presencial ou virtual.</li>
      <li>Todas as reuniões deverão ser gravadas e posteriormente disponibilizadas do portal interno para acesso e arquivamento, do Time Jurídico e da Alta Direção.</li>
    </ul>

    <p>Parágrafo único. Havendo recusa por qualquer das partes sobre a gravação da reunião, a negativa deverá ser registrada em ata com exposição dos motivos.</p>

    <ul>
      <li>Quando virtuais, as reuniões deverão, preferencialmente, serem agendadas pela PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA com envio do convite ao agente público.</li>
      <li>Quando presenciais, as reuniões deverão ocorrer, exclusivamente, nas dependências oficiais do órgão da Administração Pública ou nas dependências da PRIME LAN - COMERCIO E SERVICOS DE INFORMATICA LTDA, ficando, terminantemente proibida sua realização em ambientes que sugiram ou demonstrem inadequação ao padrão ético estabelecido nesta Política, como bares, restaurantes, etc.</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AntiCorruptionPolicy',
  title: 'UpCities - Política Anticorrupção'
};
</script>